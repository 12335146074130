import { Injectable } from '@angular/core';

import { BrowserContext } from '../../../../common/models/browser-context';
import { HttpService } from './http.service';
import { StateService } from './state.service';
import { Router } from '@angular/router';
import { catchError, firstValueFrom, from, Observable, of, tap } from 'rxjs';
import { take } from 'rxjs/operators';
import { ExtendedHttpErrorResponse } from '../../../../common/models/extended-http-error-response';
import { HttpContext } from '@angular/common/http';
import { SKIP_CALL_AUTH_CONSEQUENCES } from '../../application/interceptors/error.interceptor';
import { LoggingService } from '../../application/services/logging.service';
import { isNotNil } from './helper.service';

@Injectable()
export class AuthenticationService {
    logger = this.loggingService.init('AuthenticationService');

    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private sharedState: StateService,
        private router: Router,
    ) {}

    login(email: string, password: string) {
        return this.httpService
            .apiPost<BrowserContext>('/api/login', {
                email,
                password,
            })
            .pipe(
                take(1),
                tap((response) => {
                    this.sharedState.setContext(response);
                    this.setSessionDetails();
                }),
            );
    }

    logout() {
        this.httpService
            .apiPost('/api/logout', undefined, new HttpContext().set(SKIP_CALL_AUTH_CONSEQUENCES, true))
            .pipe(
                take(1),
                catchError(() => {
                    return of(null);
                }),
            )
            .subscribe(() => {
                this.router.navigate(['/auth/logout']).then(() => {
                    this.sharedState.clearContextAndSessionStorage();
                });
            });
    }

    getContext(skipAuthConsequences: boolean = false): Observable<BrowserContext | null> {
        const context = new HttpContext().set(SKIP_CALL_AUTH_CONSEQUENCES, skipAuthConsequences);

        return this.httpService.apiGet<BrowserContext | null>('/api/context', undefined, context).pipe(
            catchError((e: ExtendedHttpErrorResponse) => {
                return of(null);
            }),
        );
    }

    async updateContext(skipAuthConsequences: boolean = false): Promise<void> {
        const token = this.sharedState.token();
        const hasToken = isNotNil(token) && token !== '';

        const context = hasToken ? await firstValueFrom(this.getContext(skipAuthConsequences)) : null;

        if (context) {
            this.sharedState.setContext(context);
            this.sharedState.authenticated.set(true);
        } else {
            const clone = structuredClone(this.sharedState.context());
            clone.idToken = '';
            this.sharedState.setContext(clone);
            this.sharedState.authenticated.set(false);
        }
    }

    updateContext$(skipAuthConsequences: boolean = false): Observable<void> {
        return from(this.updateContext(skipAuthConsequences));
    }

    private setSessionDetails() {
        if (typeof document != 'undefined') {
            document.dispatchEvent(new Event('login'));
        }
    }

    requestOTP(email: string, type: 'login' | 'registration', locale: string, tosAgreement?: boolean) {
        const context = new HttpContext().set(SKIP_CALL_AUTH_CONSEQUENCES, true);

        return this.httpService
            .apiPost(
                '/api/login/otp',
                {
                    email,
                    locale,
                    type,
                    tosAgreement,
                },
                context,
            )
            .pipe(take(1));
    }

    loginWithOTP(email: string, otp: string, register: boolean) {
        const context = new HttpContext().set(SKIP_CALL_AUTH_CONSEQUENCES, true);

        return this.httpService
            .apiPost<BrowserContext>(
                '/api/login/otp/verify',
                {
                    email,
                    password: otp,
                    register,
                },
                context,
            )
            .pipe(
                take(1),
                tap((response) => {
                    this.sharedState.setContext(response);
                    this.setSessionDetails();
                }),
            );
    }
}
