import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { StateService } from '../services/state.service';
import { map, of } from 'rxjs';
import { NavigationService } from '../../application/services/navigation.service';
import { CanActivateObservableFn } from '../../application/util/ordered-guard';

export function authenticatedGuard(): CanActivateObservableFn {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const sharedState = inject(StateService);
        const navigationService = inject(NavigationService);

        const authenticated = sharedState.authenticated();
        const anonymous = sharedState.anonymous();

        return of(authenticated).pipe(
            map((authenticated) =>
                authenticated ? true : anonymous ? navigationService.loginLink() : navigationService.accessDeniedLink(),
            ),
        );
    };
}
