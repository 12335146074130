import { Component, input, OnDestroy, OnInit, signal } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgIf, registerLocaleData } from '@angular/common';
import localeFinnish from '@angular/common/locales/fi';
import { take } from 'rxjs/operators';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { catchError, EMPTY, filter } from 'rxjs';
import { NavigationService } from './application/services/navigation.service';
import { StateService } from './framework/services/state.service';
import { LoggingService } from './application/services/logging.service';
import { HttpService } from './framework/services/http.service';
import { SoftwareVersion } from '../../common/models/software-version';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DebugComponent } from './application/components/debug.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, TranslateModule, HttpClientModule, ToastModule, ConfirmDialogModule, DebugComponent, NgIf],
})
export class AppComponent implements OnInit, OnDestroy {
    showDebugger = signal(localStorage.getItem('debug') === 'true').asReadonly();
    logger = this.loggingService.init('AppComponent');
    customerId = input<string | null>(null);
    currentRoute = this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd)) //
        .subscribe((_) => {
            this.navigationService.currentRoute.set(this.router.url);
        });

    constructor(
        private config: PrimeNGConfig,
        private translateService: TranslateService,
        private navigationService: NavigationService,
        private sharedState: StateService,
        private router: Router,
        private loggingService: LoggingService,
        private httpService: HttpService,
    ) {}

    ngOnInit() {
        this.logger.log('version', this.sharedState.version().version);
        this.translateService.setDefaultLang('fi');
        this.translate('fi');
        this.httpService
            .apiGet<SoftwareVersion>('/api/version')
            .pipe(catchError(() => EMPTY))
            .subscribe((data) => {
                this.sharedState.version.set(data ?? null);
            });
    }

    translate(lang: string) {
        this.translateService.use(lang);
        this.translateService
            .get('primeng')
            .pipe(take(1))
            .subscribe((res) => {
                this.config.setTranslation(res);
                registerLocaleData(localeFinnish);
            });
    }

    ngOnDestroy(): void {
        this.currentRoute.unsubscribe();
    }
}
