import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { concatMap, map, Observable, of } from 'rxjs';
import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { LoggingService } from '../services/logging.service';

export type CanActivateObservableFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => Observable<boolean | UrlTree>;

export const orderedGuards = (
    guards: CanActivateObservableFn[],
    name: string | null = null,
): CanActivateObservableFn => {
    return (route, state) => {
        const injector = inject(EnvironmentInjector);
        const logger = inject(LoggingService).init('orderedGuards');

        const stream: Observable<(boolean | UrlTree)[]> = guards.reduce(
            (previousValue: Observable<(boolean | UrlTree)[]>, currentValue, currentIndex, array) => {
                return previousValue.pipe(
                    concatMap((value) => {
                        logger.log(`${name ?? ''} [${currentIndex}] value: ${value}`);
                        if (value[value.length - 1] !== true) {
                            return of([value[value.length - 1]]);
                        }
                        const source = runInInjectionContext(injector, () => currentValue(route, state));
                        return source.pipe(map((it) => [...value, it]));
                    }),
                );
            },
            of([true]),
        );

        return stream.pipe(
            map((it) => {
                if (it.length === 0) {
                    return true;
                }

                return it[it.length - 1];
            }),
        );
    };
};
