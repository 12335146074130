import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { StateService } from '../services/state.service';
import { map, Observable, of, tap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(
        private sharedState: StateService,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.handle(route, state).pipe(
            tap((it) => {
                console.log(`[AdminGuard] canActivate: ${it}`);
            }),
        );
    }

    handle(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (this.sharedState.admin()) {
            return of(true);
        }

        return this.authenticationService.updateContext$().pipe(
            map(() => {
                if (this.sharedState.admin()) {
                    return true;
                }
                return this.router.parseUrl('/auth/access-denied');
            }),
        );
    }
}
