export enum PurchaseInvoiceState {
    UNSPECIFIED = 'unspecified',
    DRAFT = 'draft',
    OPEN = 'open',
    OPEN_ERROR = 'open-error',
    OPEN_TARGETED = 'open-targeted',
    OPEN_APPROVAL = 'open-approval',
    OPEN_APPROVED = 'open-approved',
    OPEN_REJECTED = 'open-rejected',
    VERIFICATION_EXTERNAL = 'verification-external',
    APPROVAL_EXTERNAL = 'approval-external',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
}
