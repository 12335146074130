import { ApplicationConfig, importProvidersFrom, isDevMode, LOCALE_ID } from '@angular/core';
import {
    provideRouter,
    withComponentInputBinding,
    withInMemoryScrolling,
    withRouterConfig,
    withViewTransitions,
} from '@angular/router';

import { routes } from './app.routes';
import { AuthenticationService } from './framework/services/authentication.service';
import { StateService } from './framework/services/state.service';
import { provideContextStorage } from './application/providers/provide-context-storage';
import { AdminGuard } from './framework/guards/admin.guard';
import { TagTypeService } from './application/services/tag-type.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { NavigationService } from './application/services/navigation.service';
import { provideLoggingOptions } from './application/services/logging.service';
import { HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { JwtInterceptor } from './application/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './application/interceptors/error.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function HttpLoaderFactory(http: HttpBackend) {
    return new TranslateHttpLoader(new HttpClient(http), '../common/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
    providers: [
        // provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(
            routes,
            withViewTransitions(),
            withComponentInputBinding(),
            withRouterConfig({
                onSameUrlNavigation: 'reload',
                paramsInheritanceStrategy: 'always',
            }),
            withInMemoryScrolling({
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
            }),
        ),
        ConfirmationService,
        AuthenticationService,
        StateService,
        AdminGuard,
        TagTypeService,
        MessageService,
        DialogService,
        NavigationService,
        provideContextStorage(),

        provideLoggingOptions({
            disabled: !isDevMode()
                ? true
                : {
                      DataContextService: true,
                      orderedGuards: true,
                  },
        }),
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpBackend],
                },
                defaultLanguage: 'fi',
            }),
        ),
        importProvidersFrom(
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: !isDevMode(),
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000',
            }),
        ),
        importProvidersFrom([BrowserAnimationsModule]),
        provideHttpClient(withInterceptors([JwtInterceptor, ErrorInterceptor])),

        { provide: LOCALE_ID, useValue: 'fi_FI' },
    ],
};
