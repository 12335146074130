import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { of } from 'rxjs';
import { CanActivateObservableFn } from '../util/ordered-guard';
import { StateService } from '../../framework/services/state.service';
import { NavigationService } from '../services/navigation.service';

export function hasCustomerGuard(): CanActivateObservableFn {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const sharedState = inject(StateService);
        const navigationService = inject(NavigationService);
        const customerIds = sharedState.customerIds();

        if (customerIds.length > 0) {
            return of(true);
        }

        navigationService.navigateToNewCustomer();

        return of(false);
    };
}
