import { Injectable } from '@angular/core';

import { PurchaseInvoiceState } from '../../../../common/models/enumeration/purchase-invoice-state';
import { SalesInvoiceState } from '../../../../common/models/enumeration/sales-invoice-state';
import { CustomerState } from '../../../../common/models/enumeration/customer-state';
import { VehicleState } from '../../../../common/models/enumeration/vehicle-state';

@Injectable({
    providedIn: 'root',
})
export class TagTypeService {
    constructor() {}

    getTagTypeFromPurchaseInvoiceState(state: PurchaseInvoiceState): string {
        switch (state) {
            case PurchaseInvoiceState.OPEN:
            case PurchaseInvoiceState.OPEN_APPROVED:
            case PurchaseInvoiceState.OPEN_TARGETED:
                return 'info';
            case PurchaseInvoiceState.OPEN_ERROR:
                return 'danger';
            case PurchaseInvoiceState.OPEN_APPROVAL:
                return 'warning';
            case PurchaseInvoiceState.OPEN_REJECTED:
                return 'help';
            case PurchaseInvoiceState.REJECTED:
            case PurchaseInvoiceState.APPROVAL_EXTERNAL:
            case PurchaseInvoiceState.VERIFICATION_EXTERNAL:
                return 'secondary';
            case PurchaseInvoiceState.ACCEPTED:
                return 'success';
            default:
                return 'primary';
        }
    }

    getTagTypeFromSalesInvoiceState(
        state: SalesInvoiceState,
    ): 'success' | 'info' | 'warning' | 'danger' | 'secondary' | 'contrast' | undefined {
        switch (state) {
            case SalesInvoiceState.DRAFT:
            case SalesInvoiceState.APPROVAL:
                return 'warning';
            case SalesInvoiceState.APPROVED:
                return 'info';
            case SalesInvoiceState.UNSENT:
                return 'secondary';
            case SalesInvoiceState.OPEN:
            case SalesInvoiceState.PAID:
                return 'success';
            case SalesInvoiceState.OVERDUE:
            case SalesInvoiceState.REMINDED:
            case SalesInvoiceState.REQUESTED:
            case SalesInvoiceState.COLLECTED:
                return 'danger';
            case SalesInvoiceState.CANCELED:
            case SalesInvoiceState.CREDIT_LOSS:
                return 'secondary';
            default:
                return 'secondary';
        }
    }

    getTagTypeFromCustomerState(state: CustomerState): string {
        switch (state) {
            case CustomerState.OPPORTUNITY:
                return 'info';
            case CustomerState.LEAD:
                return 'warning';
            case CustomerState.CONTACTED:
                return 'danger';
            case CustomerState.ACTIVE:
                return 'success';
            case CustomerState.INACTIVE:
                return 'help';

            default:
                return 'primary';
        }
    }

    getTagTypeFromVehicleState(state: VehicleState): string {
        switch (state) {
            case VehicleState.DRAFT:
                return 'warning';
            case VehicleState.OFFER:
            case VehicleState.PENDING_APPROVAL:
                return 'danger';
            case VehicleState.ORDER:
            case VehicleState.HANDOVER_DONE:
                return 'info';
            case VehicleState.ACTIVE:
                return 'success';
            case VehicleState.REALIZATION:
            case VehicleState.SALE:
                return 'secondary';
            case VehicleState.INACTIVE:
                return 'help';
            default:
                return 'primary';
        }
    }
}
