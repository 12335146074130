import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { first, map, Observable } from 'rxjs';
import { inject } from '@angular/core';
import { AuthenticationService } from '../../framework/services/authentication.service';
import { CanActivateObservableFn } from '../util/ordered-guard';

export function updateContextAsActiveGuard(skipAuthConsequences: boolean = false): CanActivateObservableFn {
    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
        const authenticationService = inject(AuthenticationService);

        return authenticationService.updateContext$(skipAuthConsequences).pipe(
            first(),
            map(() => true),
        );
    };
}
