import { Inject, Injectable, InjectionToken, Optional, Provider } from '@angular/core';

export interface LoggingOptions {
    disabled?: boolean | Record<string, boolean>;
}
export const LOGGING_OPTIONS = new InjectionToken<LoggingOptions>('LoggingOptions');

export function provideLoggingOptions(options: LoggingOptions): Provider {
    return {
        provide: LOGGING_OPTIONS,
        useValue: options,
    };
}

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    constructor(@Optional() @Inject(LOGGING_OPTIONS) private providedOptions: LoggingOptions | null) {}

    init(prefix: string) {
        return new Logger(prefix, this.providedOptions);
    }
}

class Logger {
    private options: LoggingOptions = this.providedOptions ?? this.defaultOptions();

    constructor(
        private prefix: string,
        private providedOptions: LoggingOptions | null,
    ) {}

    private get disabled() {
        if (typeof this.options.disabled === 'boolean') {
            return this.options.disabled;
        }
        return this.options?.disabled?.[this.prefix] ?? false;
    }

    private get logPrefix() {
        return this.prefix !== '' ? `[${this.prefix}]` : null;
    }

    get log(): Function {
        if (this.disabled) {
            return () => {};
        }
        if (this.logPrefix) {
            return console.log.bind(window.console, this.logPrefix);
        } else {
            return console.log.bind(window.console);
        }
    }

    get warn(): Function {
        if (this.disabled) {
            return () => {};
        }
        if (this.logPrefix) {
            return console.warn.bind(window.console, this.logPrefix);
        } else {
            return console.warn.bind(window.console);
        }
    }

    get dir(): Function {
        if (this.disabled) {
            return () => {};
        }
        if (this.logPrefix) {
            return console.dir.bind(window.console, this.logPrefix);
        } else {
            return console.dir.bind(window.console);
        }
    }

    get error(): Function {
        if (this.disabled) {
            return () => {};
        }
        if (this.logPrefix) {
            return console.error.bind(window.console, this.logPrefix);
        } else {
            return console.error.bind(window.console);
        }
    }

    private defaultOptions(): LoggingOptions {
        return {
            disabled: false,
        };
    }
}
