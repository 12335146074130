import { inject, isDevMode } from '@angular/core';
import { HttpContextToken, HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Message, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
    ERROR_API_CALL_FAILED,
    ERROR_FORBIDDEN,
    ERROR_NOT_FOUND,
    ERROR_SERVER_ERROR,
    ERROR_UNAUTHORIZED,
    ERROR_UNPROCESSABLE_ENTITY,
} from '../../../../common/constants';
import { ExtendedHttpErrorResponse } from '../../../../common/models/extended-http-error-response';
import { StateService } from '../../framework/services/state.service';
import { Router } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import { MessageSeverity } from '../../../../common/models/MessageSeverity';

export const SKIP_CALL_AUTH_CONSEQUENCES = new HttpContextToken(() => false);

export const ErrorInterceptor: HttpInterceptorFn = (request, next) => {
    const messageService = inject(MessageService);
    const sharedState = inject(StateService);
    const router = inject(Router);
    const nav = inject(NavigationService);
    const translateService = inject(TranslateService);

    const skipAuthConsequences = request.context.get(SKIP_CALL_AUTH_CONSEQUENCES);

    function getHttpErrorResponseMessage(err: HttpErrorResponse): Message {
        if (err.error && typeof err.error === 'string') {
            return {
                severity: 'error',
                summary: 'Error',
                detail: translateService.instant(err.error),
            };
        }

        switch (err.status) {
            case 400:
                return {
                    severity: MessageSeverity.warn,
                    summary: translateService.instant(`field.errorMessage`),
                    detail: translateService.instant(ERROR_API_CALL_FAILED),
                };
            case 401:
                return {
                    severity: MessageSeverity.warn,
                    summary: translateService.instant(`field.errorMessage`),
                    detail: translateService.instant(ERROR_UNAUTHORIZED),
                };
            case 403:
                return {
                    severity: MessageSeverity.warn,
                    summary: translateService.instant(`field.errorMessage`),
                    detail: translateService.instant(ERROR_FORBIDDEN),
                };
            case 500:
                return {
                    severity: MessageSeverity.warn,
                    summary: translateService.instant(`field.errorMessage`),
                    detail: translateService.instant(ERROR_SERVER_ERROR),
                };
            case 404:
                return {
                    severity: MessageSeverity.warn,
                    summary: translateService.instant(`field.errorMessage`),
                    detail: translateService.instant(ERROR_NOT_FOUND),
                };
            case 422:
                return {
                    severity: MessageSeverity.warn,
                    summary: translateService.instant(`field.errorMessage`),
                    detail: translateService.instant(ERROR_UNPROCESSABLE_ENTITY),
                };
            default:
                return {
                    severity: MessageSeverity.warn,
                    summary: translateService.instant(`field.errorMessage`),
                    detail: `Error code: ${err.status}`,
                };
        }
    }

    function handleForbiddenApiCall() {
        if (typeof document !== 'undefined') {
            document.dispatchEvent(new Event('api-forbidden'));
            if (window.location.pathname !== '/auth/login') {
                router.navigate(['/auth/access-denied']);
            }
        }
    }

    function handleUnprocessableEntityApiCall() {
        if (typeof document !== 'undefined') {
            document.dispatchEvent(new Event('api-unprocessable-entity'));
        }
    }

    function handleUnauthorizedApiCall() {
        console.log(nav.currentRoute());
        if (typeof document !== 'undefined') {
            document.dispatchEvent(new Event('api-unauthorized'));

            if (nav.currentRoute().startsWith('/dashboard')) {
                router.navigate(['/auth/access-denied']);
            } else if (!nav.currentRoute().startsWith('/auth/login')) {
                router.navigate(['/auth/login']);
            }
        }
    }

    function handleDeleteErrorApiCall() {
        if (typeof document !== 'undefined') {
            document.dispatchEvent(new Event('api-error-delete'));
        }
    }

    function handleGeneralErrorApiCall() {
        if (typeof document !== 'undefined') {
            document.dispatchEvent(new Event('api-error'));
        }
    }

    return next(request).pipe(
        catchError((err: HttpErrorResponse) => {
            throw ExtendedHttpErrorResponse.fromHttpErrorResponse(err).addMessage(
                getHttpErrorResponseMessage(err),
                (message) => {
                    messageService.add(message);
                },
            );
        }),
        catchError((error: ExtendedHttpErrorResponse) => {
            if (!skipAuthConsequences) {
                if (error.status === 403) {
                    sharedState.clearContextAndSessionStorage();
                    handleForbiddenApiCall();
                } else if (error.status === 422) {
                    sharedState.clearContextAndSessionStorage();
                    handleUnprocessableEntityApiCall();
                } else if (error.status === 401) {
                    sharedState.clearContextAndSessionStorage();
                    handleUnauthorizedApiCall();
                } else if (error.status === 500 && request.method === 'DELETE') {
                    handleDeleteErrorApiCall();
                } else if (error.status !== 200 && error.status !== 404) {
                    handleGeneralErrorApiCall();
                }
            }

            if (isDevMode() && skipAuthConsequences) {
                console.warn('[ErrorInterceptor] skipAuthConsequences is true.');
            }

            return throwError(() => error);
        }),
    );
};
