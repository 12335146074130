import { Routes } from '@angular/router';
import { authenticatedGuard } from './framework/guards/authenticated.guard';
import { updateContextAsActiveGuard } from './application/guards/update-context-as-active.guard';
import { orderedGuards } from './application/util/ordered-guard';
import { ApplicationResource } from '../../common/models/enumeration/application-resource';
import { NotfoundComponent } from './framework/components/not-found/notfound.component';
import { hasCustomerGuard } from './application/guards/has-customer.guard';

export const routes: Routes = [
    {
        path: '',
        canActivate: [updateContextAsActiveGuard(true)],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('./application/components/public/container/container.component').then(
                        (m) => m.ContainerComponent,
                    ),
                children: [
                    {
                        path: '',
                        loadComponent: () =>
                            import('./application/components/public/landing-page/landing-page.component').then(
                                (m) => m.LandingPageComponent,
                            ),
                    },
                ],
            },
            {
                path: 'auth',
                loadComponent: () =>
                    import('./framework/components/auth/auth-layout/auth.layout.component').then(
                        (m) => m.AuthLayoutComponent,
                    ),
                children: [
                    {
                        path: 'error',
                        loadComponent: () =>
                            import('./framework/components/error/error.component').then((m) => m.ErrorComponent),
                    },
                    {
                        path: 'access-denied',
                        loadComponent: () =>
                            import('./framework/components/auth/access/access.component').then(
                                (m) => m.AccessComponent,
                            ),
                    },
                    {
                        path: 'login',
                        loadComponent: () =>
                            import('./framework/components/auth/login/login.component').then((m) => m.LoginComponent),
                    },
                    {
                        path: 'logout',
                        loadComponent: () =>
                            import('./framework/components/auth/logout/logout.component').then(
                                (m) => m.LogoutComponent,
                            ),
                    },
                    {
                        path: 'self-register',
                        loadComponent: () =>
                            import('./framework/components/auth/self-register/self-register.component').then(
                                (m) => m.SelfRegisterComponent,
                            ),
                    },
                    { path: '**', redirectTo: '/notfound' },
                ],
            },
            {
                path: 'dashboard',
                canActivate: [orderedGuards([updateContextAsActiveGuard(), authenticatedGuard()], 'dashboard')],
                loadComponent: () =>
                    import('./pages/dashboard/dashboard-outlet.component').then((m) => m.DashboardOutletComponent),
                children: [
                    {
                        path: '',
                        canActivate: [
                            orderedGuards([updateContextAsActiveGuard(), hasCustomerGuard()], 'dashboard-manager'),
                        ],
                        loadComponent: () =>
                            import('./pages/dashboard/dashboard.component').then((m) => m.DashboardComponent),
                    },
                    {
                        path: `${ApplicationResource.CUSTOMER}/create`,
                        loadComponent: () =>
                            import(
                                './application/components/user/new-first-customer/new-first-customer.component'
                            ).then((m) => m.NewFirstCustomerComponent),
                    },
                    {
                        path: `emission-report/create`,
                        canActivate: [
                            orderedGuards(
                                [updateContextAsActiveGuard(), hasCustomerGuard()],
                                '[EMISSION_REPORT][create]',
                            ),
                        ],
                        loadComponent: () =>
                            import(
                                './application/components/manager/new-emission-report/new-emission-report.component'
                            ).then((it) => it.NewEmissionReportComponent),
                    },
                    {
                        path: `emission-report/create/traficom`,
                        canActivate: [
                            orderedGuards(
                                [updateContextAsActiveGuard(), hasCustomerGuard()],
                                '[EMISSION_REPORT][create/traficom]',
                            ),
                        ],
                        loadComponent: () =>
                            import(
                                './application/components/manager/new-emission-report/traficom/traficom-guide.component'
                            ).then((it) => it.TraficomGuideComponent),
                    },
                ],
            },
            {
                path: 'profile',
                loadComponent: () =>
                    import('./pages/dashboard/dashboard-outlet.component').then((m) => m.DashboardOutletComponent),
                children: [
                    {
                        path: 'edit',
                        loadComponent: () =>
                            import('./framework/components/user/user-profile/user-profile.component').then(
                                (m) => m.UserProfileComponent,
                            ),
                    },
                    // {
                    //     path: 'api-token',
                    //     loadComponent: () =>
                    //         import('./framework/components/user/api-token/api-token.component').then(
                    //             (m) => m.ApiTokenComponent,
                    //         ),
                    // },
                    // {
                    //     path: 'api-token/add',
                    //     loadComponent: () =>
                    //         import('./framework/components/user/add-api-token/add-api-token.component').then(
                    //             (m) => m.AddApiTokenComponent,
                    //         ),
                    // },
                ],
            },
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ],
    },
];
