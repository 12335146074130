import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Message } from 'primeng/api/message';

interface HttpErrorResponseInit {
    error?: any;
    headers?: HttpHeaders;
    status?: number;
    statusText?: string;
    url?: string;
}

export class ExtendedHttpErrorResponse<T = null> extends HttpErrorResponse {
    private _payload: T | null = null;
    private _message: Message | null = null;
    private _showMessageFn: ((message: Message) => void) | null = null;

    private constructor(init: HttpErrorResponseInit) {
        super(init);
    }

    getMessage() {
        return this._message?.detail ?? null;
    }

    showMessage(): void {
        if (this._message && this._showMessageFn) {
            this._showMessageFn(this._message);
        }
    }

    static fromHttpErrorResponse<T>(httpErrorResponse: HttpErrorResponse): ExtendedHttpErrorResponse<T> {
        return new ExtendedHttpErrorResponse<T>({
            error: httpErrorResponse.error,
            url: httpErrorResponse.url ?? undefined,
            status: httpErrorResponse.status,
            statusText: httpErrorResponse.statusText,
            headers: httpErrorResponse.headers,
        });
    }

    addMessage(message: Message, showMessageFn: (message: Message) => void) {
        const instance = ExtendedHttpErrorResponse.fromHttpErrorResponse<T>(this);
        instance._message = message;
        instance._payload = this._payload;
        instance._showMessageFn = showMessageFn;
        return instance;
    }
}
