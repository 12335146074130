import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, map, of, tap } from 'rxjs';
import { CanActivateObservableFn } from '../util/ordered-guard';
import { NavigationService } from '../services/navigation.service';
import { ResourceService } from '../../framework/services/resource.service';
import { ApplicationResource } from '../../../../common/models/enumeration/application-resource';
import { ExtendedHttpErrorResponse } from '../../../../common/models/extended-http-error-response';
import { StateService } from '../../framework/services/state.service';

/**
 * this guard expects that customer to exist and already selected.
 */
export function hasCompletedEmissionReportGuard(): CanActivateObservableFn {
    const hasCompletedEmissionReportsByCustomerId = (resourceService: ResourceService, customerIds: string[]) => {
        return resourceService
            .getResourcePagingNew(
                ApplicationResource.EMISSION_REPORT,
                new Map<string, any>([['customerId', customerIds]]),
                'generated IS NOT NULL',
            )
            .pipe(map((it) => it.rowCount > 0));
    };

    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const resourceService = inject(ResourceService);
        const navigationService = inject(NavigationService);
        const stateService = inject(StateService);

        return hasCompletedEmissionReportsByCustomerId(resourceService, stateService.customerIds()).pipe(
            tap((it) => {
                if (!it) {
                    navigationService.navigateToNewEmissionReport();
                }
            }),
            catchError((err: ExtendedHttpErrorResponse) => {
                err.showMessage();
                return of(false);
            }),
        );
    };
}
