import { DateTime } from 'luxon';

export function formatCurrency(value: number | null | undefined) {
    if (typeof value === 'undefined' || value === null) {
        return '-';
    } else {
        return new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(value);
    }
}

export function isNil<TValue>(value: TValue): value is Extract<TValue, null | undefined> {
    return value === null || value === undefined;
}

export function isNotNil<TValue>(value: TValue): value is Exclude<TValue, null | undefined> {
    return value !== null && value !== undefined;
}

export function formatNumber(value: number | null | undefined) {
    if (typeof value === 'undefined' || value === null) {
        return '-';
    } else {
        return new Intl.NumberFormat('fi-FI').format(value);
    }
}

export function formatToFinnishDate(value: Date | null | undefined) {
    if (typeof value === 'undefined' || value === null) {
        return '-';
    } else {
        return new Intl.DateTimeFormat('fi-FI').format(value);
    }
}

export function sortAlphabetically(sortProperty: string) {
    return (itemA: any, itemB: any) => {
        const nameA = itemA[sortProperty].toLowerCase();
        const nameB = itemB[sortProperty].toLowerCase();

        return nameA.localeCompare(nameB);
    };
}

export const initialVehicleContractColumns: string[] = [
    'vehicleState',
    'contractInfo',
    'smartFleetContractNumber',
    'smartFleetContractStart',
    'smartFleetContractEnd',
    'vehicleRegistrationNumber',
    'vehicleMake',
    'vehicleModel',
    'customerId',
    'financingContractType',
    'financingContractFinancingVendorId',
    'costMonthlyProjected',
    'costMonthlyInvoiced',
];

export const ignoredVehicleContractColumns: string[] = [
    'financingContractRepurchaseVendorId',
    'financingContractInitialUsage',
    'financingContractContractNumber',
    'vehicleUsageUpdateDate',
    'vehicleUsageType',
    'smartFleetContractInitialUsage',
    'vehicleOfferDocumentUrl',
    'financingContractCustomerExpenseAccountId',
    'smartFleetContractCustomerExpenseAccountId',
    'financingContractUsageBudget',
    'financingContractInitialFee',
    'financingContractMonthlyManagementFee',
    'financingContractMonthlyFee',
    'financingContractFirstPayment',
    'financingContractMonthlyPayment',
    'smartFleetContractInitialFee',
    'smartFleetContractMonthlyFee',
    'smartFleetContractHandoverDate',
    'smartFleetContractVehicleUserEmail',
    'vehicleFullCarBenefit',
    'vehicleLimitedBenefit',
    'vehicleVatPercentage',
    'vehicleRetrofittedAccessoryPrice',
    'vehicleFactoryAccessoryCarTax',
    'vehicleFactoryAccessoryPriceExclCarTax',
    'vehicleDeliveryFee',
    'vehicleCarTax',
    'vehicleDiscount',
    'vehicleSuggestedRetailPrice',
    'vehicleCo2EmissionNedc',
    'vehicleIdentificationNumber',
    'modified',
    'created',
];

export const searchAbleVehicleContractColumns: string[] = [
    'vehicleMake',
    'vehicleState',
    'vehicleModel',
    'vehicleRegistrationNumber',
];

export const searchAblePurchaseInvoiceRowsColumns: string[] = ['productName', 'description'];

/*export function mapMonthlyCostsToVehicleContract(
    vehicleContract: VehicleContract,
    vehicleContractNumbers: { [vehicleContractId: string]: { [costType: string]: number } },
): VehicleContract {
    let contractCopy: VehicleContract = {
        ...vehicleContract,
    };

    Object.keys(vehicleContractNumbers[vehicleContract.id]).forEach((key) => {
        switch (key) {
            case InvoiceRowType.SMARTFLEET_MONTHLY_FEE:
                contractCopy = {
                    ...contractCopy,
                    smartFleetContractMonthlyFee: vehicleContractNumbers[vehicleContract.id][key],
                };
                break;
            case InvoiceRowType.SMARTFLEET_INITIAL_FEE:
                contractCopy = {
                    ...contractCopy,
                    smartFleetContractInitialFee: vehicleContractNumbers[vehicleContract.id][key],
                };
                break;
            case InvoiceRowType.FINANCING_MONTHLY_FEE:
                contractCopy = {
                    ...contractCopy,
                    financingContractMonthlyFee: vehicleContractNumbers[vehicleContract.id][key],
                };
                break;
            case InvoiceRowType.FINANCING_MONTHLY_MANAGEMENT_FEE:
                contractCopy = {
                    ...contractCopy,
                    financingContractMonthlyManagementFee: vehicleContractNumbers[vehicleContract.id][key],
                };
                break;
            case InvoiceRowType.FINANCING_MONTHLY_PAYMENT_LEASING:
            case InvoiceRowType.FINANCING_MONTHLY_PAYMENT_PART_PAYMENT:
            case InvoiceRowType.FINANCING_MONTHLY_SERVICE_BUDGET:
                contractCopy = {
                    ...contractCopy,
                    financingContractMonthlyPayment: vehicleContractNumbers[vehicleContract.id][key],
                };
                break;
            default:
                console.warn('Could not map sum to any number on vehicle contract');
                console.warn('Key:', key);
                console.warn('Value', vehicleContractNumbers[vehicleContract.id][key]);
                break;
        }
    });

    return contractCopy;
}*/

export function roundCurrencyValue(currencyValue: number) {
    return parseFloat(Number(Math.round(currencyValue * 100) / 100).toFixed(2));
}

/*export function calculateInvoiceRowSums(row: SalesInvoiceRow): {
    sumVat: number;
    sumVatExcluded: number;
    sumVatIncluded: number;
} {
    const sumVat = roundCurrencyValue((row.quantity * row.unitPrice * row.vatPercentage) / 100);
    const sumVatExcluded = roundCurrencyValue(row.quantity * row.unitPrice);
    const sumVatIncluded = roundCurrencyValue(sumVat + sumVatExcluded);

    return {
        sumVat,
        sumVatExcluded,
        sumVatIncluded,
    };
}*/

export function convertLocalDateToUtc(date: Date): Date {
    const utcOffset = DateTime.fromJSDate(date).offset;
    const utcStartOfDay = DateTime.fromJSDate(date, { zone: 'UTC' }).plus({ minutes: utcOffset }).startOf('day');

    return utcStartOfDay.toJSDate();
}
