import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { CanActivateObservableFn } from '../util/ordered-guard';
import { NavigationService } from '../services/navigation.service';
import { ResourceService } from '../../framework/services/resource.service';
import { ApplicationResource } from '../../../../common/models/enumeration/application-resource';
import { ExtendedHttpErrorResponse } from '../../../../common/models/extended-http-error-response';
import { StateService } from '../../framework/services/state.service';

export function hasIncompleteEmissionReportGuard(): CanActivateObservableFn {
    const call = (resourceService: ResourceService, customerIds: string[]) => {
        return resourceService
            .getResourcePagingNew(
                ApplicationResource.EMISSION_REPORT,
                new Map<string, any>([['customerId', customerIds]]),
                'generated IS NULL',
            )
            .pipe(
                catchError((err: ExtendedHttpErrorResponse) => {
                    err.showMessage();
                    return of(null);
                }),
            );
    };

    return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        const resourceService = inject(ResourceService);
        const navigationService = inject(NavigationService);
        const stateService = inject(StateService);

        return call(resourceService, stateService.customerIds()).pipe(
            map((it) => {
                if (!it) {
                    return false;
                }

                if (it.rowCount > 0) {
                    return true;
                }

                navigationService.navigateToNewEmissionReport();
                return false;
            }),
        );
    };
}
