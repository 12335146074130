import { computed, Injectable, signal } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ApplicationResource } from '../../../../common/models/enumeration/application-resource';
import { EmissionReport } from '../../../../common/models/EmissionReport';
import { encodeWithQueryParamScope, QueryParamsScope } from '../util/QueryParamsScope';
import { isNotNil } from '../../framework/services/helper.service';

export interface NavigationLink {
    urlTree: UrlTree;
    routerLink: string;
    queryParams: Record<string, string>;
}
@Injectable()
export class NavigationService {
    currentRoute = signal('/');
    dashboardUrl = signal('/dashboard').asReadonly();
    dashboard = computed(() => this.router.parseUrl(this.dashboardUrl()));
    loginLink = signal(this.router.parseUrl('/auth/login')).asReadonly();
    accessDeniedLink = signal(this.router.parseUrl('/auth/access-denied')).asReadonly();

    currentManagerDashboardLink = computed(() => {
        const routerLink = `/dashboard`;

        return {
            routerLink,
            urlTree: this.router.parseUrl(routerLink),
            queryParams: {},
        };
    });

    constructor(public router: Router) {}

    async navigateToManagerDashboard(customerId: string | null, emissionReportId: string | null) {
        const queryParams = encodeWithQueryParamScope(
            {
                customerId,
                emissionReportId,
            },
            QueryParamsScope.DASHBOARD,
        );
        return this.router.navigate(['/dashboard'], {
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    newCustomerLink(): NavigationLink {
        const routerLink = `/dashboard/${ApplicationResource.CUSTOMER}/create`;

        return {
            routerLink,
            urlTree: this.router.parseUrl(routerLink),
            queryParams: {},
        };
    }
    async navigateToNewCustomer() {
        const { routerLink, urlTree, queryParams } = this.newCustomerLink();
        return this.router.navigate([routerLink], {
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    newEmissionReportLink(): {
        routerLink: string;
        queryParams: Record<string, string>;
    } {
        const url = `/dashboard/${ApplicationResource.EMISSION_REPORT}/create`;

        return {
            routerLink: url,
            queryParams: {},
        };
    }
    async navigateToNewEmissionReport() {
        // this.dataContextService.updateUrlParam(UrlParamName.customerId, customerId);
        const link = this.newEmissionReportLink();
        return this.router.navigate([link.routerLink], {
            queryParams: link.queryParams,
        });
    }

    async navigateToEditEmissionReport(emissionReport?: Pick<EmissionReport, 'customerId' | 'id'>) {
        const queryParams = isNotNil(emissionReport)
            ? encodeWithQueryParamScope(
                  {
                      customerId: emissionReport.customerId,
                      emissionReportId: emissionReport.id,
                  },
                  QueryParamsScope.DASHBOARD,
              )
            : null;

        return this.router.navigate(['/dashboard'], {
            queryParams: queryParams,
        });
    }

    async navigateToViewEmissionReport(customerId: string | null, customerReportId: string | null) {
        if (customerId && customerReportId) {
            return this.router.navigateByUrl(
                `/${ApplicationResource.CUSTOMER}/${customerId}/${ApplicationResource.EMISSION_REPORT}/view/${customerReportId}`,
            );
        }
        return false;
    }
}
