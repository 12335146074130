import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { v4 } from 'uuid';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { Resource } from '../../../../common/models/enumeration/resource';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private http: HttpClient) {}

    apiDelete<T>(path: string): Observable<T> {
        return this.jsonRequest('DELETE', path, undefined, undefined);
    }

    apiPost<T>(path: string, requestBody: any, context?: HttpContext): Observable<T> {
        return this.jsonRequest('POST', path, requestBody, undefined, context);
    }

    apiGetResourcesNew<T>(path: string, requestBody: any): Observable<T> {
        return this.jsonRequest('POST', path, requestBody, undefined);
    }

    apiPut<T>(path: string, requestBody: any): Observable<T> {
        return this.jsonRequest('PUT', path, requestBody, undefined);
    }

    apiGet<T>(path: string, queryParameters?: any, context?: HttpContext): Observable<T> {
        return this.jsonRequest('GET', path, undefined, queryParameters, context);
    }

    apiUrl(path: string): URL {
        return typeof window !== 'undefined'
            ? new URL(environment.apiUrl + path)
            : new URL('http://127.0.0.1:8889' + path); // tests
    }

    jsonRequest(
        method: string,
        path: string,
        requestBody: any,
        queryParameters: any,
        context?: HttpContext,
    ): Observable<any> {
        const requestId = v4();
        const headers = {
            'Content-Type': 'application/json',
            'Request-ID': requestId,
        };

        const url = this.apiUrl(path);

        if (queryParameters) {
            Object.keys(queryParameters).forEach((key) => {
                if (queryParameters[key] !== undefined) {
                    url.searchParams.append(key, queryParameters[key]);
                }
            });
        }

        const requestBodyJson = JSON.stringify(requestBody, null, 2);

        return this.http.request(method, url.toString(), {
            headers,
            body: requestBodyJson,
            context: context,
        });
    }

    pdfRequest(method: string, path: string, requestBody?: any, context?: HttpContext): Observable<Blob> {
        const requestId = v4();

        const url = this.apiUrl(path);

        const requestBodyJson = JSON.stringify(requestBody, null, 2);

        const headers = new HttpHeaders()
            .set('Request-ID', requestId)
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/pdf');

        return this.http.request(method, url.toString(), {
            headers,
            body: requestBodyJson,
            context: context,
            responseType: 'blob',
        });
    }

    apiUpload(file: File, resource: Resource, category: Resource) {
        const formData = new FormData();
        const url = this.apiUrl(`/api/file/resource/${resource}/category/${category}`);

        formData.append('fileBinary', file);

        return this.http.post<{ id: string }>(url.toString(), formData);
    }
}
