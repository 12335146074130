import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { StateService } from '../../framework/services/state.service';
import { isNotNil } from '../../framework/services/helper.service';

export const JwtInterceptor: HttpInterceptorFn = (request, next) => {
    const sharedState = inject(StateService);

    const token = sharedState.token();

    if (isNotNil(token)) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
        });
    } else {
        request = request.clone({
            withCredentials: true,
        });
    }

    // console.log(`[JwtInterceptor] jwt included: ${!!token}`);

    return next(request);
};
