import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    P_PAGE,
    P_PAGE_SIZE,
    P_QUERY,
    P_SORT_FIELD,
    P_SORT_FIELDS,
    P_SORT_ORDER,
    P_SORT_ORDERS,
} from '../../../../common/constants';
import { HttpService } from './http.service';
import { PagingInfo } from '../../../../common/models/paging-info';
import { Resource } from '../../../../common/models/enumeration/resource';
import { ResourceValue } from '../../../../common/models/enumeration/resource-value';

@Injectable({
    providedIn: 'root',
})
export class ResourceService {
    constructor(private httpService: HttpService) {}

    getResource<T>(resourceType: string, id: string): Observable<T> {
        const url = `/api/resource/${resourceType}/${id}`;

        return this.httpService.apiGet<T>(url);
    }

    getResourcePaging(
        resourceType: string,
        parameters?: Map<string, string>,
        pageSize: number | undefined = undefined,
    ): Observable<PagingInfo> {
        const queryParameters = parameters
            ? '?' + Array.from(parameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
            : '';

        const url = `/api/resource/${resourceType}/paging${queryParameters}`;

        return this.httpService.apiGet<PagingInfo>(url, {
            pageSize,
        });
    }

    getResources<T>(resourceType: string, parameters: Map<string, any>, pagination: string): Observable<T[]> {
        const url = `/api/resource-new/${resourceType}?${pagination}`;
        const whereParams: { [key: string]: any } = {};
        const query = parameters!!.get('query');

        parameters.forEach((value, key) => {
            whereParams[key] = value;
        });

        return this.httpService.apiPost<T[]>(url, {
            ...whereParams,
            query,
        });
    }

    getResourcesNew<T>(
        resourceType: string,
        parameters?: Map<string, any>,
        customPart?: string,
        skip = false,
    ): Observable<T[]> {
        let body: any = {};
        let queryParameters = '';

        const pagingParameters = new Map<string, string>();
        let queryString = '';

        if (parameters) {
            const query = this.buildQuery(parameters, queryString, body, pagingParameters, customPart);
            queryParameters = query.queryParameters;
            body = query.body;
        }

        if (skip) {
            body = {
                ...body,
                query: parameters!!.get('query'),
            };
        }

        return this.httpService.apiGetResourcesNew<T[]>(`/api/resource-new/${resourceType}${queryParameters}`, body);
    }

    getResourcePagingNew(
        resourceType: string,
        parameters?: Map<string, any>,
        customPart?: string,
        skip = false,
    ): Observable<PagingInfo> {
        let body: any = {};
        let queryParameters = '';

        const pagingParameters = new Map<string, string>();
        let queryString = '';

        if (parameters) {
            const query = this.buildQuery(parameters, queryString, body, pagingParameters, customPart);
            queryParameters = query.queryParameters;
            body = query.body;
        }

        if (skip) {
            body = {
                ...body,
                query: parameters!!.get('query'),
            };
        }

        return this.httpService.apiPost<PagingInfo>(`/api/resource-new/${resourceType}/paging${queryParameters}`, body);
    }

    deleteResource<T>(resourceType: string, id: string): Observable<void> {
        return this.httpService.apiDelete<void>(`/api/resource/${resourceType}/${id}`);
    }

    postResource<T>(resourceType: string, resource: T): Observable<T> {
        return this.httpService.apiPost<T>(`/api/resource/${resourceType}`, resource).pipe();
    }

    putResource<T>(resourceType: string, id: string, resource: T): Observable<T> {
        return this.httpService.apiPut<T>(`/api/resource/${resourceType}/${id}`, resource);
    }

    private buildQuery(
        parameters: Map<string, any>,
        queryString: string,
        body: any,
        pagingParameters: Map<string, string>,
        customPart = '',
    ) {
        const pagingKeys = [P_SORT_FIELD, P_SORT_FIELDS, P_SORT_ORDER, P_SORT_ORDERS, P_PAGE, P_PAGE_SIZE];
        const paginationKeys: string[] = [];
        const whereKeys: string[] = [];

        Array.from(parameters.keys()).forEach((key) => {
            if (pagingKeys.indexOf(key) > -1) {
                paginationKeys.push(key);
            } else {
                whereKeys.push(key);
            }
        });

        paginationKeys.forEach((key) => {
            if (parameters.get(key)) {
                pagingParameters.set(key, parameters.get(key)!!);
            }
        });

        whereKeys.forEach((key, index) => {
            const splitParam = parameters.get(key)!!.toString().split('_');
            const parameterType = Array.isArray(parameters.get(key))
                ? 'IN'
                : splitParam.length > 1
                  ? splitParam[0]
                  : '=';
            const queryValue = this.generateQueryValueString(key.toString(), parameterType);

            if (customPart.indexOf(key) === -1) {
                if (index === 0) {
                    queryString += `${key} ${parameterType} ${queryValue}`;
                } else {
                    queryString += ` AND ${key} ${parameterType} ${queryValue}`;
                }
            }

            if (splitParam.length > 1) {
                body[key] = splitParam[1];
            } else {
                body[key] = parameters.get(key);
            }
        });

        if (queryString.length) {
            body[P_QUERY] = queryString;
        }

        if (customPart.length) {
            if (body[P_QUERY] && body[P_QUERY].length) {
                body[P_QUERY] += ` AND ${customPart}`;
            } else {
                body[P_QUERY] += `${customPart}`;
            }
        }

        const queryParameters = pagingParameters
            ? '?' + Array.from(pagingParameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
            : '';

        return {
            body,
            queryString,
            queryParameters,
        };
    }

    private generateQueryValueString(value: string, paramType = '='): string {
        if (paramType === 'IN') {
            return `({${value}})`;
        } else {
            return `{${value}}`;
        }
    }

    getResourceByIdTyped<T extends Resource>(resource: T, id: string): Observable<ResourceValue[T]> {
        return this.getResource<ResourceValue[T]>(resource, id);
    }
    getResourcesByIdsTyped<T extends Resource>(
        resource: T,
        ids: string[],
        field = 'id',
    ): Observable<ResourceValue[T][]> {
        return this.getResourcesNew<ResourceValue[T]>(resource, new Map<string, any>([[field, ids]]));
    }
}
