export enum AdminVehicleState {
    UNSPECIFIED = 'unspecified',
    DRAFT = 'draft',
    OFFER = 'offer',
    PENDING_APPROVAL = 'pending-approval',
    HANDOVER_DONE = 'handover-done',
    SALE = 'sale',
    ENDING = 'ending',
}

export enum CustomerVehicleState {
    ORDER = 'order',
    ACTIVE = 'active',
    REALIZATION = 'realization',
    INACTIVE = 'inactive',
}

export const VehicleState = {
    ...AdminVehicleState,
    ...CustomerVehicleState,
};

export type VehicleState = AdminVehicleState | CustomerVehicleState;
