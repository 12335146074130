import { computed, effect, Inject, Injectable, InjectionToken, signal } from '@angular/core';
import { BrowserContext } from '../../../../common/models/browser-context';
import { Scopes } from '../../../../common/models/scopes';
import { SoftwareVersion } from '../../../../common/models/software-version';
import { FrameworkUserRole } from '../../../../common/models/enumeration/framework-user-role';
import { Role } from '../../../../common/models/enumeration/role';
import { Feature } from '../../../../common/models/enumeration/feature';

export interface MetadataResult {
    resources: Array<ResourceMetadata>;
}

export interface ResourceMetadata {
    resource: string;
    fieldValues: Array<{ field: string; stringValues: Array<string>; numberValues: Array<number> }>;
}

export interface ContextStorage {
    save: (id: string) => void;
    load: () => string | null;
    reset: () => void;
}

export const CONTEXT_STORAGE = new InjectionToken<ContextStorage>('ContextStorage');

@Injectable()
export class StateService {
    private _context = signal<BrowserContext>(this.getStorageContext() ?? this.getAnonymousContext());

    public context = this._context.asReadonly();

    token = computed(() => {
        const ctx = this.context();
        return (ctx.idToken && ctx.idToken) !== '' ? ctx.idToken : null;
    });
    customerIds = computed(() => {
        return this.context().scopes.manager.customerId ?? [];
    });

    admin = computed(() => {
        return this.context().roles.indexOf(FrameworkUserRole.ADMIN) !== -1;
    });

    manager = computed(() => {
        return this.context().roles.indexOf(FrameworkUserRole.MANAGER) !== -1;
    });

    metadata = signal<MetadataResult>({ resources: [] });
    version = signal<SoftwareVersion>({ logo: '', name: '', title: '', version: '', features: [] });
    authenticated = signal(false);

    navigation: any[] = [];
    navigationStartMenus: any[] = [];
    navigationEndMenus: any[] = [];

    routeAfterLogin: string | undefined;

    mobileBurgerOpen = false;
    connected = false;
    private loading = 0;

    constructor(@Inject(CONTEXT_STORAGE) private contextStorage: ContextStorage) {
        effect(() => {
            const context = this.context();

            if (context.idToken === '') {
                this.contextStorage.reset();
                console.log(`The context storage cleared!`);
                return;
            }
            // console.log('saved id-token to context storage.');
            this.contextStorage.save(context.idToken);
        });
    }

    anonymous = computed(() => {
        return this.context().userId === 'anonymous';
    });

    hasRole(role: Role) {
        return this.context().roles.indexOf(role.toString()) !== -1;
    }

    enabled(feature: Feature) {
        return this.version().features.indexOf(feature) !== -1;
    }

    isLoading() {
        return this.loading > 0;
    }

    increaseLoadingCounter() {
        this.loading++;
    }

    decreaseLoadingCounter() {
        setTimeout(() => {
            if (this.loading > 0) {
                this.loading--;
            }
        }, 250);
    }

    setContext(context: BrowserContext | undefined): void {
        if (!context) {
            this._context.set(this.getAnonymousContext());
            this.authenticated.set(false);
        } else {
            this._context.set(context);
            this.authenticated.set(true);
        }
    }

    clearContextAndSessionStorage() {
        this.setContext(undefined);
    }

    private getStorageContext(): BrowserContext | null {
        const contextStorageToken = this.contextStorage.load();
        if (!contextStorageToken) {
            return null;
        }
        return {
            userId: 'anonymous',
            email: 'anonymous',
            roles: [],
            groups: [],
            idToken: contextStorageToken,
            serviceLogo: '',
            serviceName: '',
            serviceTitle: '',
            version: '',
            locale: '',
            scopes: this.getEmptyScopes(),
        };
    }

    private getAnonymousContext(): BrowserContext {
        return {
            userId: 'anonymous',
            email: 'anonymous',
            roles: [],
            groups: [],
            idToken: '',
            serviceLogo: '',
            serviceName: '',
            serviceTitle: '',
            version: '',
            locale: '',
            scopes: this.getEmptyScopes(),
        };
    }

    private getEmptyScopes(): Scopes {
        let scopes = {} as Scopes;
        scopes = this.getEmptyApplicationScopes(scopes);
        scopes = this.getEmptyFrameworkScopes(scopes);
        return scopes;
    }

    private getEmptyApplicationScopes(scopes: Scopes): Scopes {
        return {
            ...scopes,
            manager: {
                spaceId: [],
                customerId: [],
            },
        };
    }

    private getEmptyFrameworkScopes(scopes: Scopes): Scopes {
        return {
            ...scopes,
            manager: {
                spaceId: [],
                customerId: [],
            },
            editor: {
                spaceId: [],
            },
            viewer: {
                spaceId: [],
            },
        };
    }
}
