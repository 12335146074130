export enum ApplicationResource {
    ACCOUNT = 'account',
    CUSTOMER = 'customer',
    PURCHASE_INVOICE = 'purchase-invoice',
    PURCHASE_INVOICE_ROW = 'purchase-invoice-row',
    PURCHASE_INVOICE_ROW_MAPPING = 'purchase-invoice-row-mapping',
    SALES_INVOICE = 'sales-invoice',
    SALES_INVOICE_ROW = 'sales-invoice-row',
    VEHICLE_CONTRACT = 'vehicle-contract',
    VENDOR = 'vendor',
    VENDOR_CONTRACT = 'vendor-contract',
    NETWHEELS_QUERY = 'netwheels-query',
    VEHICLE_COST_MONTHLY = 'vehicle-cost-monthly',
    VEHICLE_COST_MONTHLY_ROW = 'vehicle-cost-monthly-row',
    VEHICLE_CONTRACT_COMMENT = 'vehicle-contract-comment',
    VEHICLE_USAGE = 'vehicle-usage',
    VENDOR_LOCATION = 'vendor-location',
    FILE_META = 'file-meta',
    FINVOICE_PARSER_PATTERN = 'finvoice-parser-pattern',
    VEHICLE = 'vehicle',
    VEHICLE_MAKE = 'vehicle-make',
    VEHICLE_COST_MONTHLY_REPORT = 'vehicle-cost-monthly-report',
    REPORT = 'report',
    VEHICLE_CONTRACT_PURCHASE_INVOICE = 'vehicle-contract-purchase-invoice',
    VENDOR_PURCHASE_INVOICE = 'vendor-purchase-invoice',
    CUSTOMER_VENDOR_CONTRACT = 'customer-vendor-contract',
    DOCUMENT = 'document',
    SMART_FLEET_TASK = 'smart-fleet-task',
    NOTIFICATION = 'notification',
    NOTIFICATION_RECIPIENT = 'notification-recipient',
    USER_SF_CONNECTION = 'user-sf-connection',
    CUSTOMER_SF_CONNECTION = 'customer-sf-connection',
    EMISSION_REPORT = 'emission-report',
    EMISSION_REPORT_VEHICLE = 'emission-report-vehicle',
}
