export enum SalesInvoiceState {
    UNSPECIFIED = 'unspecified',
    DRAFT = 'draft',
    APPROVAL = 'approval',
    APPROVED = 'approved',
    CANCELED = 'canceled',
    UNSENT = 'unsent',
    OPEN = 'open',
    OVERDUE = 'overdue',
    REMINDED = 'reminded',
    REQUESTED = 'requested',
    COLLECTED = 'collected',
    CREDIT_LOSS = 'credit-loss',
    PAID = 'paid',
}
