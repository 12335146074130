/**
 * Idea of this enum is to add a prefix to query params when storing them in url and removing the prefix when loading from url.
 * This will prevent saving some reused query params like Pagination params or others.
 * Each key should isolate the given params.
 */
export enum QueryParamsScope {
    DASHBOARD = 'd',
}

/**
 * Includes given scope in record keys in scope:key format
 * @param record
 * @param scope
 */
export function encodeWithQueryParamScope<
    T extends Record<string, unknown>,
    KEY extends string & keyof T,
    SCOPE extends QueryParamsScope,
>(record: T, scope: SCOPE): Record<`${SCOPE}:${KEY}`, T[KEY]> {
    const entries = Object.entries(record) as [KEY, T[KEY]][];

    return entries.reduce(
        (
            previousValue: Record<`${SCOPE}:${KEY}`, T[KEY]>,
            currentValue: [KEY, T[KEY]],
        ): Record<`${SCOPE}:${KEY}`, T[KEY]> => {
            const key: `${SCOPE}:${KEY}` = `${scope}:${currentValue[0]}`;

            previousValue[key] = currentValue[1];
            return previousValue as Record<`${SCOPE}:${KEY}`, T[KEY]>;
        },
        {} as Record<`${SCOPE}:${KEY}`, T[KEY]>,
    );
}

/**
 * Excludes given scope from record keys.
 * Keys that don't start with scope will be excluded from output.
 * @param record
 * @param scope
 */
export function decodeQueryParamScope<
    T extends Record<string, unknown>,
    KEY extends string & keyof T,
    SCOPE extends QueryParamsScope,
>(record: T, scope: SCOPE): Record<string, unknown> {
    const entries = Object.entries(record) as [KEY, T[KEY]][];

    return entries
        .filter((it) => it[0].startsWith(`${scope}:`))
        .reduce((previousValue: T, currentValue: [KEY, T[KEY]]): T => {
            const key = currentValue[0].split(`${scope}:`)[1];
            (previousValue as Record<string, unknown>)[key] = currentValue[1];
            return previousValue as T;
        }, {} as T);
}
