import { computed, Injectable, signal } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ApplicationResource } from '../../../../common/models/enumeration/application-resource';

export interface NavigationLink {
    urlTree: UrlTree;
    routerLink: string;
    queryParams: Record<string, string>;
}
@Injectable()
export class NavigationService {
    currentRoute = signal('/');
    dashboardUrl = signal('/dashboard').asReadonly();
    dashboard = computed(() => this.router.parseUrl(this.dashboardUrl()));
    loginLink = signal(this.router.parseUrl('/auth/login')).asReadonly();
    accessDeniedLink = signal(this.router.parseUrl('/auth/access-denied')).asReadonly();

    currentManagerDashboardLink = computed(() => {
        const routerLink = `/dashboard`;

        return {
            routerLink,
            urlTree: this.router.parseUrl(routerLink),
            queryParams: {},
        };
    });

    constructor(public router: Router) {}

    managerDashboardLink(customerId: string | null, emissionReportId: string | null): NavigationLink {
        const routerLink = `/dashboard`;
        const queryParams: Record<string, string> = {};
        if (customerId) {
            queryParams['dcuid'] = customerId;
            if (emissionReportId) {
                queryParams['derid'] = emissionReportId;
            }
        }
        return {
            routerLink,
            urlTree: this.router.parseUrl(routerLink),
            queryParams: queryParams,
        };
    }

    async navigateToManagerDashboard(customerId: string | null, emissionReportId: string | null) {
        const { routerLink, urlTree, queryParams } = this.managerDashboardLink(customerId, emissionReportId);
        return this.router.navigate([routerLink], {
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    newCustomerLink(): NavigationLink {
        const routerLink = `/dashboard/${ApplicationResource.CUSTOMER}/create`;

        return {
            routerLink,
            urlTree: this.router.parseUrl(routerLink),
            queryParams: {},
        };
    }
    async navigateToNewCustomer() {
        const { routerLink, urlTree, queryParams } = this.newCustomerLink();
        return this.router.navigate([routerLink], {
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    newEmissionReportLink(): {
        routerLink: string;
        queryParams: Record<string, string>;
    } {
        const url = `/dashboard/${ApplicationResource.EMISSION_REPORT}/create`;

        return {
            routerLink: url,
            queryParams: {},
        };
    }
    async navigateToNewEmissionReport() {
        // this.dataContextService.updateUrlParam(UrlParamName.customerId, customerId);
        const link = this.newEmissionReportLink();
        return this.router.navigate([link.routerLink], {
            queryParams: link.queryParams,
        });
    }

    editEmissionReportLink(): {
        routerLink: string;
        queryParams: Record<string, string>;
    } {
        const url = `/dashboard/${ApplicationResource.EMISSION_REPORT}/edit`;

        return {
            routerLink: url,
            queryParams: {},
        };
    }
    async navigateToEditEmissionReport() {
        // this.dataContextService.updateUrlParam(UrlParamName.customerId, customerId);
        // this.dataContextService.updateUrlParam(UrlParamName.emissionReportId, emissionReportId);
        const link = this.editEmissionReportLink();
        return this.router.navigate([link.routerLink], {
            queryParams: link.queryParams,
        });
    }

    async navigateToViewEmissionReport(customerId: string | null, customerReportId: string | null) {
        if (customerId && customerReportId) {
            return this.router.navigateByUrl(
                `/${ApplicationResource.CUSTOMER}/${customerId}/${ApplicationResource.EMISSION_REPORT}/view/${customerReportId}`,
            );
        }
        return false;
    }
}
