import { Component } from '@angular/core';
import { JsonPipe } from '@angular/common';
import { NavigationService } from '../services/navigation.service';
import { StateService } from '../../framework/services/state.service';
import { AccordionModule } from 'primeng/accordion';
import { environment } from '../../../environment/environment';

@Component({
    standalone: true,
    imports: [JsonPipe, AccordionModule],
    providers: [],
    selector: 'app-debug',
    template: ` <p-accordion>
        <p-accordionTab header="DEBUGGER">
            <div class="debug">
                <div class="debug-name">authenticated:</div>
                <div class="debug-value">{{ stateService.authenticated() | json }}</div>

                <div class="debug-name">currentRoute:</div>
                <div class="debug-value">{{ nav.currentRoute() | json }}</div>

                <div class="debug-name">context:</div>
                <div class="debug-value">
                    <code>
                        <pre>
                        {{ stateService.context() | json }}
                    </pre>
                    </code>
                </div>
                <div class="debug-name">environment</div>
                <div class="debug-value">{{ environment | json }}</div>
            </div>
        </p-accordionTab>
    </p-accordion>`,

    styles: [
        `
            :host {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                padding: 1rem;
            }

            .debug {
                display: grid;
                grid-template-columns: min-content minmax(0, 1fr);
            }
        `,
    ],
    animations: [],
})
export class DebugComponent {
    constructor(
        public nav: NavigationService,
        public stateService: StateService,
    ) {}

    protected readonly environment = environment;
}
