import { EnvironmentProviders, Provider } from '@angular/core';
import { CONTEXT_STORAGE, ContextStorage } from '../../framework/services/state.service';

export function provideContextStorage(debug = false): Provider | EnvironmentProviders {
    const logger = (...data: any) => {
        if (debug) {
            console.log(...data);
        }
    };
    return {
        provide: CONTEXT_STORAGE,
        useValue: {
            reset: () => {
                logger('[CONTEXT_STORAGE] reset');
                sessionStorage.clear();
            },
            load: () => {
                const id = sessionStorage.getItem('id-token');
                logger(`[CONTEXT_STORAGE] load token (${id ? 'present' : 'not present'})`);

                return id;
            },
            save: (id) => {
                if (id === sessionStorage.getItem('id-token')) {
                    logger(`[CONTEXT_STORAGE] save skipped (same value)`);
                    return;
                }

                logger(`[CONTEXT_STORAGE] save: ${id}`);
                sessionStorage.setItem('id-token', id);
            },
        } satisfies ContextStorage,
    };
}
